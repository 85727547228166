import { Fragment, lazy } from 'react'

// section component
const HomeSection = lazy(() => import('../../../homeSections/HomeSection'))
const Aboutus = lazy(() => import('../../../homeSections/Aboutus'))
const Services = lazy(() => import('../../../homeSections/Services'))
const Features = lazy(() => import('../../../homeSections/Features'))
const TechBlock = lazy(() => import('../../../homeSections/TechBlock'))
const Testimonial = lazy(() => import('../../../homeSections/Testimonial'))
const GetStarted = lazy(() => import('../../../homeSections/GetStarted'))
const Blog = lazy(() => import('../../../homeSections/Blog'))
const Contact = lazy(() => import('../../../homeSections/Contact'))
const Info = lazy(() => import('../../../homeSections/Info'))


function HomePage() {

  const sections = [HomeSection, Aboutus, Services, Features, TechBlock, Testimonial, Info, Blog, Contact]

  return (
    <>
      <div >
        {sections.map((Components, index) => (
          <Fragment key={index}>
            <Components />
          </Fragment>
        ))}
      </div>
    </>
  )
}

export default HomePage
