import { Link } from "react-router-dom";
import { useState } from "react";

export const SocialIcon = ({ 
    path="", 
    url="", 
    classs, 
    icon, 
    target=""
}) => {
    return(
        <>
        {
            url !== "" ?
            <a href={ url } className={ classs } target = { target }>
                {icon}
            </a> 
            :
            <Link to={ path } className={ classs } target ={ target }>
                { icon }
            </Link>
        }
        </>
    )
}
