import { lazy, useState } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import routes from './routes';
import { ScrollToTop } from '../common/ScrollToTop';
const Navbar  = lazy(() => import("../homeSections/Navbar"))
const Footer = lazy(() => import("../homeSections/Footer"))
const PageNotFound = lazy(() => import("../layout/pages/PageNotFound"))
const ThemeSettings = lazy(() => import("../layout/ThemeSettings"))
const CustomizeTheme = lazy(() => import("../homeSections/CustomizeTheme"))

const App = () => {
    
    return (<>
            <BrowserRouter>
                <Navbar/>
                    <ScrollToTop>
                        <Switch>
                            {routes.map((elem, inx) => <Route exact path={elem.path} key={inx}><elem.Component /></Route> ) }
                            <Route path="*" ><PageNotFound /></Route>
                        </Switch>
                    </ScrollToTop>
                <Footer/>
                <CustomizeTheme />
                <ThemeSettings /> 
            </BrowserRouter>
        </>
    )
}

export default App
