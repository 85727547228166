import React, { Suspense } from 'react'
import TermsContent from "../../../content/Terms&CoContent.json";
import Loading, { LoadTimer } from '../Loading';

function TermCondition() {
    const terms = TermsContent
  return (
        <>
        <LoadTimer timeOut={ 1000 }>
            <Suspense fallback= { <Loading /> } >
            <section className="section scroll-select mt-5" id="features">
                <div className="container">
                    <div className="row vertical-content">
                        <div className="col-lg">
                            <div className="features-box">
                                <h3>{terms.title}</h3>
                                <p className="text-muted web-desc">{terms.text}</p>
                                <ul className="text-muted list-unstyled margin-t-30 features-item-list">
                                    {terms.list?.map((elem,i) => {
                                        return (
                                            <li className="" key={i}>{elem.listdata}</li>
                                        )
                                    })}
                                </ul>
                                <a href="#" className="btn btn-custom margin-t-30 waves-effect waves-light">Learn More <i className="mdi mdi-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </Suspense>
        </LoadTimer>
        </>
  )
}

export default TermCondition
