import React, {Suspense} from "react";
import { Row, Col } from "antd";
import Loading,{ LoadTimer } from "../Loading";

const PrivacyPolicy = () => {
  const styles = {
    privacyPolicyContent: {
      marginBottom: "1rem",
    },
  };
  return (
    <>
    <LoadTimer timeOut={ 1000 }>
    <Suspense fallback={ <Loading /> }>
      <Row className="privacy-policy-page privacy-policy">
        <Row>
          <Row className="privacy-policy-page-heading">
            <h2>Privacy Policy</h2>
          </Row>
          <Row className="header-desc">
            <Col>
              <p>
                This Privacy Policy describes how PantherCodx LLP. (PantherCodx,
                “We”, “Us” , or “Our” ) protects your privacy when you use
                Solwin (the PantherCodx LLP Service”) which includes the Web
                site located at panthercodx.com (the “Site” ) and all
                PantherCodx LLP mobile phone applications (“Mobile App” ).
              </p>
              <p>
                PLEASE READ THIS PRIVACY POLICY CAREFULLY. BY ACCESSING OR USING
                THE SERVICE, YOU AGREE TO BE BOUND BY THE TERMS AND CONDITIONS
                DESCRIBED HEREIN AND ALL TERMS AND CONDITIONS INCORPORATED BY
                REFERENCE. IF YOU DO NOT AGREE TO ALL OF THE TERMS AND
                CONDITIONS SET FORTH BELOW, YOU MAY NOT USE THE PantherCodx LLP
                SERVICE.
              </p>
              <p>
                Protecting your privacy and the information you provide us is a
                top priority for PantherCodx LLP and thus we strive to protect
                the confidentiality of minutest of your informations. By
                visiting our Website, you are accepting the practices described
                in this Privacy Policy and are bound by it. Any information
                submitted to PantherCodx LLP, is collected, managed and retained
                by PantherCodx LLP. Please be aware that your access to our
                Services may be subject to certain third party terms and
                conditions and privacy policies, including but not limited to
                third party payment processors. You agree that PantherCodx LLP
                is not liable for any such third party terms and conditions and
                their use of your personal information (information related to
                you that can identify you). We do not transfer, sell or rent
                your personal information to third parties in any way other than
                what is stated in this Privacy Policy. Unless stated otherwise,
                this Privacy Policy only deals with the use and communication of
                information collected by PantherCodx LLP. If you do not agree to
                our Policy please do not use the Site.
              </p>
            </Col>
          </Row>
        </Row>

        <Row className="privacy-policy-contents">
          <Col style={styles.privacyPolicyContent} className="content">
            <Row className="content-heading">
              <h4>1. What information do we collect?</h4>
            </Row>
            <Row className="content-desc">
              <p>
                The personal information you disclose to us. In Short: We
                collect personal information that you provide to us. We collect
                personal information that you voluntarily provide to us when you
                express an interest in obtaining information about us or our
                products and Services when you participate in activities on the
                Website or otherwise when you contact us.
              </p>
              <p>
                The personal information that we collect depends on the context
                of your interactions with us and the Website, the choices you
                make, and the products and features you use. The personal
                information we collect may include the following:
              </p>
              <Col>
                <h6>A) Personal Information Provided by You.</h6>
                <p>
                  We collect names; phone numbers; email addresses; mailing
                  addresses; and other similar information.
                </p>
              </Col>
              <p>
                All personal information that you provide to us must be true,
                complete, and accurate, and you must notify us of any changes to
                such personal information.
              </p>
              <Col>
                <h6>B) Information automatically collected</h6>
                <p>
                  Some information — such as your Internet Protocol (IP) address
                  and/or browser and device characteristics — is collected
                  automatically when you visit our website. We automatically
                  collect certain information when you visit, use or navigate
                  the website. This information does not reveal your specific
                  identity (like your name or contact information) but may
                  include device and usage information, such as your IP address,
                  browser, and device characteristics, operating system,
                  language preferences, referring URLs, device name, country,
                  location, information about how and when you use our Website
                  and other technical information. This information is primarily
                  needed to maintain the security and operation of our Website,
                  and for our internal analytics and reporting purposes.
                </p>
              </Col>
              <Col>
                <Col>
                  <h5>The information we collect includes:</h5>
                  <Col>
                    <h6>A) Log and Usage Data</h6>
                    <p>
                      Log and usage data are service-related, diagnostic, usage,
                      and performance information our servers automatically
                      collect when you access or use our Website and which we
                      record in log files. Depending on how you interact with
                      us, this log data may include your IP address, device
                      information, browser type, and settings, and information
                      about your activity on the Website (such as the date/time
                      stamps associated with your usage, pages, and files
                      viewed, searches and other actions you take such as which
                      features you use), device event information (such as
                      system activity, error reports (sometimes called 'crash
                      dumps') and hardware settings).
                    </p>
                  </Col>
                  <Col>
                    <h6>B) Device Data</h6>
                    <p>
                      We collect device data such as information about your
                      computer, phone, tablet, or other devices you use to
                      access the Website. Depending on the device used, this
                      device data may include information such as your IP
                      address (or proxy server), device and application
                      identification numbers, location, browser type, hardware
                      model Internet service provider and/or mobile carrier,
                      operating system, and system configuration information.
                    </p>
                  </Col>
                  <Col>
                    <h6>C) Location Data</h6>
                    <p>
                      We collect location data such as information about your
                      device's location, which can be either precise or
                      imprecise. How much information we collect depends on the
                      type and settings of the device you use to access the
                      Website. For example, we may use GPS and other
                      technologies to collect geolocation data that tells us
                      your current location (based on your IP address). You can
                      opt-out of allowing us to collect this information either
                      by refusing access to the information or by disabling your
                      Location setting on your device.
                    </p>
                  </Col>
                </Col>
                <Col>
                  <strong>Note:</strong> however, if you choose to opt-out, you
                  may not be able to use certain aspects of the Services.
                </Col>
              </Col>
            </Row>
          </Col>

          <Col style={styles.privacyPolicyContent} className="content">
            <Row className="content-heading">
              <h4>2. How do we use your information?</h4>
            </Row>
            <Row className="content-desc">
              <p>
                We process your information for purposes based on legitimate
                business interests, the fulfillment of our contract with you,
                compliance with our legal obligations, and/or your consent. We
                use personal information collected via our Website for a variety
                of business purposes described below. We process your personal
                information for these purposes in reliance on our legitimate
                business interests to enter into or perform a contract with you,
                with your consent, and/or for compliance with our legal
                obligations. We indicate the specific processing grounds we rely
                on next to each purpose listed below
              </p>
            </Row>
            <Col>
              <h5>We use the information we collect or receive</h5>
              <p>
                To send administrative information to you. We may use your
                personal information to send you a product, service, and new
                feature information, and/or information about changes to our
                terms, conditions, and policies.
              </p>
              <p>
                To protect our Services. We may use your information as part of
                our efforts to keep our Website safe and secure (for example,
                for fraud monitoring and prevention).
              </p>
              <p>
                To enforce our terms, conditions, and policies for business
                purposes, comply with legal and regulatory requirements, or
                connect with our contract.
              </p>
              <p>
                To respond to legal requests and prevent harm. If we receive a
                subpoena or other legal request, we may need to inspect the data
                we hold to determine how to respond.
              </p>
              <p>
                To send you marketing and promotional communications. We and/or
                our third-party marketing partners may use the personal
                information you send to us for our marketing purposes if this is
                in accordance with your marketing preferences. For example, when
                expressing an interest in obtaining information about us or our
                Website, subscribing to marketing, or otherwise contacting us,
                we will collect personal information from you.
              </p>
              <p>
                You can opt out of our marketing emails at any time (see the
                "WHAT ARE YOUR PRIVACY RIGHTS?" below). ​
              </p>
              <p>
                Deliver targeted advertising to you. We may use your information
                to develop and display personalized content and advertising (and
                work with third parties who do so) tailored to your interests
                and/or location and to measure its effectiveness.
              </p>
            </Col>
          </Col>

          <Col style={styles.privacyPolicyContent} className="content">
            <Row className="content-heading">
              <h4>3. Will your information be shared with anyone?</h4>
            </Row>
            <Row className="content-desc">
              <p>
                We only share information with your consent, to comply with
                laws, to provide you with services, to protect your rights, or
                to fulfill business obligations.
              </p>
              <p>
                We may process or share your data that we hold based on the
                following legal basis:
              </p>
              <p>
                Consent: We may process your data if you have given us specific
                consent to use your personal information for a specific purpose.
              </p>
              <p>
                Legitimate Interests: We may process your data when it is
                reasonably necessary to achieve our legitimate business
                interests.
              </p>
              <p>
                Performance of a Contract: Where we have entered into a contract
                with you, we may process your personal information to fulfill
                the terms of our contract.
              </p>
              <p>
                Legal Obligations: We may disclose your information where we are
                legally required to do so in order to comply with applicable
                law, governmental requests, a judicial proceeding, court order,
                or legal processes, such as in response to a court order or a
                subpoena (including in response to public authorities to meet
                national security or law enforcement requirements).
              </p>
              <p>
                Vital Interests: We may disclose your information where we
                believe it is necessary to investigate, prevent, or take action
                regarding potential violations of our policies, suspected fraud,
                situations involving potential threats to the safety of any
                person and illegal activities, or as evidence in litigation in
                which we are involved.
              </p>
              <p>
                More specifically, we may need to process your data or share
                your personal information in the following situations:
              </p>
              <p>
                Business Transfers. We may share or transfer your information in
                connection with, or during negotiations of, any merger, sale of
                company assets, financing, or acquisition of all or a portion of
                our business to another company.
              </p>
            </Row>
          </Col>

          <Col style={styles.privacyPolicyContent} className="content">
            <Row className="content-heading">
              <h4>4. Cookies Policy</h4>
            </Row>
            <Row className="content-desc">
              <p>
                We are using cookies to improve your experience of our Website.
                When you visit our Website, PantherCodx LLP servers send a
                cookie to your web browser. You can delete these cookies at any
                time. Standing alone, cookies do not personally identify an
                individual. Cookies merely recognize the web browser.Session
                Cookies: If you are using our Services and you choose to
                identify yourself to us, PantherCodx LLP uses session cookies
                containing encrypted information to allow PantherCodx LLP to
                uniquely identify you. Each time you log into the Services, a
                session cookie containing an encrypted, unique identifier that
                is tied to your account and is placed on your browser. These
                session cookies allow PantherCodx LLP to uniquely identify you
                when you are logged into the Services and download our products
                and to process online data transactions and requests. Session
                cookies are required to use the Services.
              </p>
            </Row>
          </Col>

          <Col style={styles.privacyPolicyContent} className="content">
            <Row className="content-heading">
              <h4>5. DO NOT TRACK</h4>
            </Row>
            <Row className="content-desc">
              <p>
                The PantherCodx LLP Services do not currently observe Do Not
                Track settings.
              </p>
            </Row>
          </Col>
          <Col style={styles.privacyPolicyContent} className="content">
            <Row className="content-heading">
              <h4>6. Do we make updates to this notice?</h4>
            </Row>
            <Row className="content-desc">
              <p>
                Yes, we will update this notice as necessary to stay compliant
                with relevant laws.
              </p>
              <p>
                We may update this privacy notice from time to time. The updated
                version will be indicated by an updated "Revised" date and the
                updated version will be effective as soon as it is accessible.
                If we make material changes to this privacy notice, we may
                notify you either by prominently posting a notice of such
                changes or by directly sending you a notification. We encourage
                you to review this privacy notice frequently to be informed of
                how we are protecting your information.
              </p>
            </Row>
          </Col>
          <Col style={styles.privacyPolicyContent} className="content">
            <Row className="content-heading">
              <h4>7. QUESTIONS AND CONTACT INFORMATION</h4>
            </Row>
            <Row className="content-desc">
              <p>
                If you have any questions about this privacy statement or the
                practices of this site, please contact us at
                info@panthercodx.com.
              </p>
            </Row>
          </Col>
        </Row>
      </Row>
      </Suspense>
      </LoadTimer>
    </>
  );
};

export default PrivacyPolicy;
