import React, { Fragment } from 'react';
import { socialURL } from "../common/helper";
import { SocialIcon } from '../common/Custom';

function FormSection() {
    return (
        <>
            <section className="section-lg bg-web-desc form_sec">
                <div className="bg-overlay form_rad"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <h2 className="text-white">Contact Information</h2>
                            <p className="padding-t-15 home-desc">Fill up the form and our Team will get back to you within 24 hours.</p>
                        </div>
                    <div className="col-lg-12 text-center mt-5">
                        <p className="margin-b-0 contact-title text-white"><i className="pe-7s-call"></i><span className='cinfo'> &nbsp;+91 9313566063 </span></p>
                    </div>
                    <div className="col-lg-12 text-center margin-t-30 ">
                        <p className="contact-title text-white"><i className="pe-7s-mail-open"></i><span className='cinfo'>&nbsp; info.panthercodx@gmail.com</span></p>
                    </div>
                    <div className="col-lg-12 text-center">
                        <ul className="list-inline social mt-5">
                            {
                                socialURL.map((elem, ind)=> (
                                    <Fragment key={'social-icon' + ind}>
                                        <li className="list-inline-item">
                                            <SocialIcon 
                                                url = {elem.url}
                                                icon = {elem.icon}
                                                target = {elem.target}
                                                classs = "social-icon"
                                            />
                                        </li>
                                    </Fragment>
                                ))
                            }
                        </ul>
                    </div>
                    </div>
                </div>
                <div className="bg-pattern-effect">
                    <img src="Image/bg-pattern.png" alt="" />
                </div>
            </section>
        </>
    )
}

export default FormSection
