// eslint-disable-next-line 
import { useState, useEffect } from "react";
import { notification } from "antd";
import axios from "axios";
import validate, { validation } from "./validationRules";
import { Base64 } from "../helper";

export const ApplyForm = (validate, defaultValues ) => {
  const [values, setValues] = useState(defaultValues);
  const [errors, setErrors] = useState({});
  const [imagefile, setImagefile] = useState([]);
  const [imgs, setimgs] = useState('')
  const [selGender, setSelGender] = useState("")
  const [buttonState, setButtonState] = useState(true)

  const Notify = (key="success", message = "message", description="description") => {
    notification[key]({
      message: key,
      description: description,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const errorkeys = Object.keys(validate(values))
    const formkeys = Object.keys(values)
    const index = formkeys.findIndex((elm) => elm === "images")
    formkeys.splice(index,1)

    if(errorkeys.some(item => formkeys.includes(item))) {
        Notify("error", "Error", "Please fill all fields");
        return setErrors(validate(values));
    }else{
      setErrors(validate(values));
    }

    // Your url for API
    let payloadString = "";
    formkeys.map((item) => 
      { return payloadString = (payloadString === "" ? 
                                `${item}: ${values[item]}` 
                                : (payloadString.concat(" \n ", `${item}: ${item === 'imageURL' 
                                ? `${ values[item].length > 0 && values[item].map((img, i) => `<${img}|View Image-${i}> `)}`     
                                : values[item]}`))
                                ) })
    const data = {
      text : payloadString
    }

    const payload = JSON.stringify(data);

    // const url = "https://hooks.slack.com/services/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";
    const newURL = 'https://hooks.slack.com/services/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';
    
    // ---------- axios slack webhook -----------
      axios
      .post(newURL, payload )
      .then(() => {
            Notify("success", "Success", "message has been sent");
            setValues(defaultValues)
            event.target[5].checked = false
            event.target[6].checked = false
      })
      .catch((error) => {
           Notify("error", "Error", "message failed");
      })
  };

  const ImageToURL = (obj,file,key) => {

      let fileData = new FormData()
      fileData.append(`file`, file[0])
      fileData.append("title", "Shakes the cat")
      
        const url = "https://slack.com/api/files.upload" 
        const header = {
          headers : {
            "Content-types" : "multipart/form-data",
            "Authorization" : 'Bearer xoxp-1593208054903-3215328169781-4531078563398-bc255dbff79f2cc7f8bdebe14795dcd2',
          }
        }
        
      Base64(file[0])
      .then(res => {
          axios.post(url, fileData, header)
          .then(response => {
            // console.log("res", response)
            let imageURL = response.data.file.url_private
            Notify("success", "Success", "Image Uploaded SuccessFully")
            obj[key].push(res);
            obj.imageURL.push(imageURL)
            setImagefile([...obj[key]]);
          })
          .catch(err => Notify("error", "Error", "Failed to Upload Image"))
      })
      
      
  }
  
  const handleChange = (event) => {
    event.persist();

    let key = event.target.name;
    let value = event.target.value;

    let obj = {
      ...values,
    }
    if(key === "gender") {
      obj[key] = value
    }else if (key === "checkbox"){
      obj[key] = event.target.checked
    }else if (key === "images") {
      const file = event.target.files[0]
      if(file.type === "image/jpeg" || file.type === "image/png" || file.type === "application/pdf"){
        ImageToURL(obj,event.target.files, key);
      }else{
        Notify("error","Invalid Image Format","Image must be jpeg or png formate")
      }
    }else {
      obj[key] = value
    }

    if(validation(obj)) setButtonState(false)
    else setButtonState(true)

    setValues(obj)
  };

  
  
  return {
    handleChange,
    handleSubmit,
    setValues,
    values,
    errors,
    imgs,
    buttonState
  };
};
