import React, { Fragment, lazy, Suspense, useState } from "react";
import { Row, Col, Card, Collapse, Tabs } from "antd";
import CareersContent from "../../../content/CareersContent.json";
import { LoadTimer } from "../Loading";
import Loading from "../Loading";
const JobCard = lazy(() => import("./JobCard"));


const CareersPage = () => {

  const job = CareersContent;
  
  return (
    <LoadTimer timeOut = { 1000 }>
    <Suspense fallback={ <Loading /> }>
      <section className="careers-section mt-5">
        <div className="container">
          <Row className="careers-main">
            <Row className="page-title-wrapper">
              {/* <h1 className="page-title">{ job.title }</h1>
              <h2 className="page-heading">{ job.text }</h2> */}
              <div className="col-lg-8 offset-lg-2">
                  <h1 className="section-title text-center">{job.title}</h1>
                  <div className="section-title-border"></div>
                  <p className="section-subtitle text-muted text-center padding-t-30 font-secondary">
                      {job.text}
                  </p>
              </div>
            </Row>
       
            <Row gutter={16} className="careers-box">

              { job.jobList.map((item, i) => (
                <Fragment key={i}>
                  <JobCard 
                    title = { item.title }
                    vacancies = { item.vacancies }
                    description = { item.description }
                    otherData = {[
                      {
                        key : "Require Experience",
                        value : item.requiredExperience
                      },
                      {
                        key : "Role & Responsibilities",
                        value : item.responsibilities
                      },
                      {
                        key : "Skills Requirement",
                        value : item.skillsRequired
                      },
                      ]}
                  />
                </Fragment>
              )) }
            </Row>
          </Row>
        </div>
      </section>
    </Suspense>
      </LoadTimer>
  );
};

export default CareersPage;

