
export default function validate(values) {
  let errors = {} ;
  if (!values.name) {
    errors.name = "Name is required";
  }
  if (!values.lastname) {
    errors.lastname = "Last Name is required";
  }
  if (!values.email) {
    errors.email = "Email address is required";
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = "Email address is invalid";
  }
  if (!values.subject) {
    errors.subject = "Subject is required";
  }
  if (!values.contact) {
    errors.contact = "Contact No. is required";
  } 
   if (!values.experience) {
    errors.experience = "Experience is required";
  }
  if (!values.gender) {
    errors.gender = "Gender is required";
  }
  if (!values.messages) {
    errors.messages= "Message is required";
  }
  if (!values.images) {
    errors.images = "Image is required";
  }
  if ( !values.checkbox) {
    errors.checkbox = "checkbox is required";
  }
  return errors;
}

export const validation = (data) => {
  let validate = Object.entries(data).every(([key, value]) => {

      if(key === 'email'){
        let pattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
        return pattern.test(value)
      }else if(key === "contact") {
        let patterns = /^(\([+]\d{2}\)|[+]\d{2}\s)([0-9]{5}[\-][0-9]{5}|[0-9]{10})$/
        return patterns.test(value)
      }
      else if(key === 'checkbox') {
        return value
      }
      else return value !== ""
    })
  return validate
}