import React,{Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import "admin-lte";
import "admin-lte/dist/css/adminlte.css";
import "@fortawesome/fontawesome-free/css/all.css";
import reportWebVitals from './reportWebVitals';
import App from "./Router/App";
import "../node_modules/bootstrap/dist/js/bootstrap";
import "./css/index.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import Loading from './layout/pages/Loading';
import { LoadTimer } from './layout/pages/Loading';
import "antd/dist/antd.min.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Suspense fallback={<LoadTimer />}>
        <App />
    </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
