import React, { useEffect, useState } from 'react'
import { ApplyForm } from '../common/validation/ApplyForm';
import validate from '../common/validation/validationRules';
import FormSection from './FormSection';
import Error from "../common/Error"


function ApplyNow() {

    const defaultValues = {
        name : "", 
        lastname: "",
        email: "", 
        contact: "",
        experience: "",
        gender : "",
        messages: "",
        images: [],
        imageURL : [],
        checkbox: false
    }
    const { values, buttonState, setValues, handleChange, handleSubmit, errors } = ApplyForm(validate, defaultValues );

    const removeImage = (index) => {
        let imgArr = values.images;
        let imgURLarr = values.imageURL
        imgArr.splice(index,1)
        imgURLarr.splice(index,1)
        setValues({...values, images : imgArr, imageURL : imgURLarr})
      }

    return (
        <>
            <section className="section bg-light scroll-select mt-3" id="">
                <div className="container">
                    <div className="row vertical-content">
                        <div className="col-lg">
                            <div className="features-box ">
                                <h3 className='d-flex justify-content-center text-center'>Apply & schedule an interview</h3>
                                <p className="text-danger web-desc d-flex justify-content-center mb-5 text-center">
                                    *site is under construction. you can directly email us on "hr.panthercodx@gmail.com" </p>
                            </div>
                            <div className='row rounded bg-white p-2 d-flex form_con'>
                                
                                <div className='col-12 col-lg-4 fc_sec'>
                                    <FormSection />
                                </div>
                                <div className='col-12 col-lg-8 mt-lg-0 mt-4 left fc_sec p-4'>

                                    <form onSubmit={handleSubmit} className='form-horizontal' id="quickForm">
                                        <div className="card-body card-body-pa">
                                            <div className='d-flex fm_sec'>
                                                <div className="col-12 col-sm-6 fm_sec_01">
                                                    <div className=" form-group  mb-3">
                                                        <label htmlFor="inputEmail3" className='col-sm col-form-label px-0'>Name :</label>
                                                        <input
                                                            name='name'
                                                            id='name'
                                                            value={values.name || ""}
                                                            onChange={handleChange}
                                                            type="text"
                                                            className="form-control form-control-border border-width-2 px-0"
                                                            placeholder="Name"
                                                            maxLength="50"
                                                        />
                                                        { Object.keys(errors).includes("name") && <Error error = {errors.name} classes="mt-3"/> }
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 fm_sec_01">
                                                    <div className=" form-group  mb-3">
                                                        <label htmlFor="inputEmail4" className='col-sm col-form-label px-0'>Last Name :</label>
                                                        <input
                                                            name='lastname'
                                                            id='lastname'
                                                            value={values.lastname || ""}
                                                            onChange={handleChange}
                                                            type="text"
                                                            className="form-control form-control-border border-width-2 px-0"
                                                            placeholder="Last Name"
                                                            maxLength="50"
                                                        />
                                                        { Object.keys(errors).includes("lastname") && <Error error = {errors.lastname} classes="mt-3"/> }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='d-flex align-items-baseline fm_sec'>
                                                <div className='col-12 col-sm-6 fm_sec_01'>
                                                    <div className="form-group row mb-3">
                                                        <label htmlFor="exampleInputPassword1" className='col-sm col-form-label'>Email :</label>
                                                        <div className="">
                                                            <input
                                                                name='email'
                                                                id='email'
                                                                value={values.email || ""}
                                                                onChange={handleChange}
                                                                type="email"
                                                                className="form-control form-control-border border-width-2 px-0"
                                                                placeholder="Email Address"
                                                                maxLength="150"
                                                            />
                                                            { Object.keys(errors).includes("email") && <Error error = {errors.email} classes="mt-3"/> }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-12 col-sm-6 fm_sec_01'>
                                                    <div className="form-group mb-3">
                                                        <label htmlFor="exampleInputPassword1">Contact No</label>
                                                        <input
                                                            name='contact'
                                                            id='contact'
                                                            value={values.contact || ""}
                                                            onChange={handleChange}
                                                            type="text"
                                                            className="form-control form-control-border border-width-2 px-0"
                                                            placeholder="+91 00000-000000"
                                                        />
                                                        { Object.keys(errors).includes("contact") && <Error error = {errors.contact} classes="mt-3"/> }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='d-flex align-items-baseline fm_sec'>
                                                <div className='col-12 col-sm-6 fm_sec_01'>
                                                    <div className="form-group mb-3">
                                                        <label htmlFor="exampleInputPassword1">Experience</label>
                                                        <input
                                                            name='experience'
                                                            id='experience'
                                                            value={values.experience || ""}
                                                            onChange={handleChange}
                                                            type="text"
                                                            className="form-control form-control-border border-width-2 px-0"
                                                            placeholder="Experience"
                                                            maxLength="150"
                                                        />
                                                        { Object.keys(errors).includes("experience") && <Error error = {errors.experience} classes="mt-3"/> }
                                                    </div>
                                                </div>
                                                <div className='col-12 col-sm-6 fm_sec_01'>
                                                    <div className="form-group mb-3">
                                                        <label htmlFor="exampleInputPassword1">Gender</label>
                                                        <div className='mt-3'>
                                                            <div className="custom-control custom-radio d-flex">
                                                                <input
                                                                    value='Male'
                                                                    onChange={handleChange}
                                                                    className="custom-control-input mx-5 form-control-border border-width-2"
                                                                    type="radio"
                                                                    id="customRadio1"
                                                                    name="gender"
                                                                />
                                                                <label htmlFor="customRadio1" className="custom-control-label">Male</label>
                                                                <div className='fr'>
                                                                    <input
                                                                        value="Female"
                                                                        onChange={handleChange}
                                                                        className="custom-control-input "
                                                                        type="radio"
                                                                        id="customRadio2"
                                                                        name="gender"
                                                                    />
                                                                    <label htmlFor="customRadio2" className="custom-control-label">Female</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        { Object.keys(errors).includes("gender") && <Error error = {errors.gender} classes="mt-3"/> }

                                                    </div>

                                                </div>

                                            </div>
                                            <div className="form-group px-2">
                                                <label>Message</label>
                                                <textarea
                                                    name='messages'
                                                    id='messages'
                                                    value={values.messages || ""}
                                                    onChange={handleChange}
                                                    className="form-control px-2"
                                                    rows="3"
                                                    placeholder="Type here ..."
                                                    maxLength="500"
                                                >

                                                </textarea>
                                                { Object.keys(errors).includes("messages") && <Error error = {errors.messages}/> }
                                            </div>
                                            <div className="form-group px-2">
                                                <label>Images</label>
                                                <div className="input-group">
                                                    <div className="custom-file">
                                                        <input
                                                            type="file"
                                                            onChange={handleChange}
                                                            className="custom-file-input"
                                                            id="exampleInputFile"
                                                            name='images'
                                                            accept=".jpg,.jpeg,.png,.pdf"
                                                            multiple
                                                        />
                                                        <label className="custom-file-label" htmlFor="exampleInputFile">Choose file *jpg *jpeg *png *pdf</label>
                                                    </div>
                                                </div>
                                                { values.images.length > 0 && (
                                                    <div className="my-3 img-container" >
                                                        {
                                                            values.images?.map((image, i) => (
                                                                <div key={i} className="form-img position-relative d-flex mr-2" style={{ height: "100px", width: "100px" }}>
                                                                    <span
                                                                        className="position-absolute"
                                                                        onClick = {() => removeImage(i) }
                                                                    >
                                                                        <i className="mdi mdi-18 mdi-close-circle" style={{color : "#fb3e3e" }}></i>
                                                                    </span>
                                                                    <img src={image} style={{ width: "100%", height: "auto", objectFit: "contain" }}/>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                )} 
                                                { Object.keys(errors).includes("images") && <Error error = {errors.images} classes="mt-3"/> }

                                            </div>
                                            <div className="form-group mb-4 px-2">
                                                <div className="custom-control custom-checkbox">
                                                    <input
                                                        checked={values.checkbox}
                                                        type="checkbox"
                                                        name="checkbox"
                                                        className="custom-control-input"
                                                        onChange={handleChange}
                                                        id="exampleCheck1" />
                                                    <label className="custom-control-label" htmlFor="exampleCheck1">I agree to the <a href="#" style={{color : "#fb3e3e"}}>terms of service</a>.</label>
                                                </div>
                                                { Object.keys(errors).includes("checkbox") && <Error error = {errors.checkbox} classes="mt-2"/> }
                                            </div>
                                            <div className=" ml-auto px-2">
                                                <button 
                                                    type="submit" 
                                                    className="btn btn-custom  waves-effect waves-light" 
                                                    disabled = { buttonState }
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ApplyNow
